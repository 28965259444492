<template>
  <div class="send-mail">
    <div class="send-mail__header">
      <div>
        <strong>{{ messageTitle }}</strong>
      </div>
      <div class="back grey--text pointer" @click="closeDialog">
        <v-icon>mdi-arrow-left</v-icon>
        Скрыть
      </div>
    </div>
    <div class="send-mail__body">
      <div class="send-mail__avatar">
        <v-avatar
            color="#FFFFFF"
            size="50"
        >
          <img
              :src="require('@/assets/img/users/profile-avatar.png')"
              alt=""
          >
        </v-avatar>
      </div>
      <div class="send-mail__fields">
        <v-text-field
            class="evi-text-field mb-2"
            color="#44D370"
            placeholder="Кому"
            v-model="mailTo"
        />
        <v-text-field
            class="evi-text-field mb-2"
            color="#44D370"
            placeholder="Тема"
            v-model="mailSubject"
        />
        <v-textarea
            no-resize
            class="evi-textarea mb-5"
            color="#44D370"
            v-model="text"
            ref="text-message"
            hide-details
            placeholder="Напишите сообщение"
            height="250"
        >
          <template v-slot:append>
            <EmojiPicker @pushEmoji="pushEmoji"/>
            <v-icon @click="send" color="#219653" class="pointer ml-5">mdi-send</v-icon>
          </template>
          <template v-slot:prepend-inner>
            <v-file-input
                hide-input
                class="attach-files"
                multiple
                v-model="attachments"
            />
          </template>
        </v-textarea>
        <div class="added-files" v-if="attachments.length">
          <div class="d-flex align-center mb-2">
            <div class="added-files__title mr-2">Прикреплено - {{filesCount}}</div>
            (<div class="added-files__title pointer clear" @click="attachments = []">Очистить</div>)
          </div>
          <div class="attachments__items">
            <div class="attachments__item" v-for="file of attachments" :key="file.id">{{file.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmojiPicker from "../EmojiPicker.vue";
import {mapActions} from "vuex";
import {pluralize} from "../../tools/Helpers";

export default {
  name: "SendMail",
  props: {
    destination: String,
    subject: String,
    action: String,
    messageId: String,
  },
  components: {EmojiPicker},
  data() {
    return {
      mailTo: '',
      mailSubject: '',
      text: '',
      attachments: [],
    }
  },
  computed: {
    filesCount() {
      let files = ['файл', 'файла', 'файлов'];

      return pluralize(this.attachments.length, files);
    },
    messageTitle () {
      if (this.action === 'forward') {
        return 'Переслать письмо';
      } else if (this.action === 'reply') {
        return 'Ответ на письмо';
      }
      return 'Новое письмо';
    }
  },
  methods: {
    ...mapActions({
      mailSend: 'mail/mailSend',
    }),
    closeDialog () {
      this.text = '';
      // this.mailSubject = '';
      // this.mailTo = '';
      this.attachments = [];
      this.$emit('close');
    },
    pushEmoji(e) {
      this.$refs['text-message'].focus();
      this.text += e;
    },
    send () {
      if (this.attachments.length) {
        //Если с файлами
        let formData = new FormData();

        this.attachments.forEach((item) => {
          formData.append('attachments[]', item);
        });

        this.mailTo.split(',').forEach((item) => {
          formData.append('to[]', item);
        });

        if (this.action) {
          formData.append('action', this.action);
          formData.append('message_id', this.messageId);
        }

        formData.append('subject',this.mailSubject);
        formData.append('text', this.text);

        this.mailSend({
          id: this.$route.params.mailId,
          body: formData,
        }).then(() => this.closeDialog());
      } else {
        const body = {
          to: this.mailTo.split(','),
          subject: this.mailSubject,
          text: this.text,
        };
        if (this.action) {
          body.action = this.action;
          body.message_id = this.messageId;
        }
        this.mailSend({
          id: this.$route.params.mailId,
          body: body
        }).then(() => this.closeDialog());
      }
    }
  },
  mounted() {
    if (this.subject) {
      this.mailSubject = this.subject;
    }
  },
  watch: {
    destination: {
      handler (val) {
        if (val) {
          this.mailTo = val;
        }
      },
      immediate: true,
    },
    subject: {
      handler (val) {
        if (val) {
          this.mailSubject = val;
        }
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss">
.send-mail {
  background: #FFFFFF;
  border-radius: 10px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCE1E6;
    padding: 17px 23px;
    font-size: 14px;
    margin-bottom: 20px;
    background: #F7F7F7;

    strong {
      font-size: 16px;
    }
  }

  &__body {
    padding: 17px 23px 43px;
    display: flex;
    align-items: flex-start;
  }
  &__avatar {
    margin-right: 42px;
  }

  &__fields {
    width: 100%;
    margin-right: 150px;
    margin-top: -20px;
  }
  .attach-files {
    margin-top: 0;
    padding-top: 0;
  }
  .v-input__prepend-inner {
    margin-top: 0;
  }
  .added-files {
    &__title {
      color: $evi-headline-color;
      font-size: 14px;
      font-weight: 600;
      &.clear {
        color: #289E80;
      }
    }
  }
}
</style>
