<template>
  <v-row class="mail">
    <v-col cols="3" class="mail__navigation">
      <v-autocomplete
          outlined
          color="#44D370"
          dense
          class="evi-autocomplete--black flex-grow-0"
          :items="mails"
          item-text="name"
          item-value="id"
          :loading="mailLoading"
          :search-input.sync="mailSearch"
          @input.native="searchMail(mailSearch)"
          item-color="green"
          v-model="selectedMail"
      >
      </v-autocomplete>
      <div v-if="selectedMail" class="mb-7">
        <div v-if="foldersLoading">
          <v-skeleton-loader
              type="list-item"
              width="100%"
              height="45px"
              v-for="i in 5"
              :key="i"
              class="mail__navigation-item d-flex"
          />
        </div>
        <div v-else>
          <div class="mail__navigation-item d-flex" :class="{'font-weight-bold' : isNavigationActive(item)}"
               v-for="(item, index) in folders" :key="index" @click="selectSection(item)">
            <img v-if="item.icon" :src="require(`@/assets/img/mail/${item.icon.toLowerCase()}.svg`)" class="mr-3"
                 alt="icon"/>
            <img v-else :src="require(`@/assets/img/mail/default.svg`)" class="mr-3" alt="icon"/>
            {{ item.title || item.name }}
            <v-badge
                color="#FF6553"
                :content="item.status.unseen"
                :value="item.status.unseen"
                class="ml-4 mt-3"
                v-if="item.status.unseen > 0"
            >
            </v-badge>
          </div>
        </div>
      </div>
      <v-btn class="evi-button-green evi-button-green--fill" v-if="selectedMail && hasRight('mail.send')" @click="sendMailModal = true">
        Написать
      </v-btn>
    </v-col>
    <v-col class="mail__wrapper">
      <div class="mail-skeleton" v-if="loading && selectedMessageId">
        <v-skeleton-loader
            type="card, list-item, list-item, list-item, list-item"
            width="100%%"
            height="100%"
        />
      </div>
      <router-view v-else-if="selectedMail && selectedSection" :folder="selectedSection" :page="page" :perPage="perPage"
                   v-show="true" @openPage="openPage" @updateMessages="getMessages(getMailMessagesWithoutLoading)"/>
      <div v-else-if="!selectedMail" class="mail-empty">Выберите почту</div>
    </v-col>
    <v-dialog
        v-model="sendMailModal"
        max-width="1124"
        transition="slide-x-transition"
        hide-overlay
    >
      <SendMail @close="sendMailModal = false"/>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {MailAutocompleteRequestsMixin} from "@/mixins/autocomplete/MailAutocompleteRequestsMixin";
import SendMail from "../../components/mail/SendMail.vue";
import {hasRight} from "../../utils/access/hasRight";
import {eventBus} from "../../main";

export default {
  name: "Mail",
  components: {SendMail},
  mixins: [MailAutocompleteRequestsMixin],
  data() {
    return {
      timerAutoUpdateMessages: null,
      selectedSection: null,
      selectedMail: null,
      selectedMessageId: null,
      page: 1,
      perPage: 50,
      foldersLoading: false,
      sendMailModal: false,
    }
  },
  computed: {
    ...mapState("common", ["loading",]),
    ...mapState("mail", ["folders", "message", "mails"]),
    ...mapState("user", ["profile"]),
    queryArgs() {
      const args = {
        per_page: this.perPage,
        page: this.page,
      };

      for (const key in this.$route.query) {
        if (key !== 'page' && key !== 'per_page') {
          args[key] = this.$route.query[key]
        }
      }

      return args;
    },
  },
  methods: {
    ...mapActions({
      getMailFolders: 'mail/getMailFolders',
      getMailFoldersWithoutLoading: 'mail/getMailFoldersWithoutLoading',
      getMailMessages: 'mail/getMailMessages',
      getMailMessagesWithoutLoading: 'mail/getMailMessagesWithoutLoading',
      getMailMessage: 'mail/getMailMessage',
      clearMessage: 'mail/clearMessage',
      clearFolders: 'mail/clearFolders',
    }),
    hasRight: hasRight,
    isNavigationActive(value) {
      if (this.selectedSection) {
        return value.name === this.selectedSection.name;
      }
    },
    openPage(value) {
      if (value === 'next') {
        this.page += 1;
      } else if (value === 'prev') {
        this.page -= 1;
      }
      this.$router.replace({
        query: this.queryArgs,
      }).catch(() => {
      });
    },
    selectSection(value) {
      this.selectedSection = value;
      this.selectedMessageId = null;

      this.$router.replace({
        path: `/mail/${this.selectedMail}/${this.selectedSection.name}`,
        query: this.queryArgs,
      }).catch(() => {
      });
    },
    getMessages(action) {
      if (this.selectedMail && this.selectedSection) {
        const args = {
          id: this.selectedMail,
          query: {
            path: this.selectedSection.name,
            ...this.queryArgs
          }
        };
        action(args);
      }
    },
    setAutoUpdateMailMessagesSettings (val) {
      let ms = 30000;
      clearTimeout(this.timerAutoUpdateMessages);
      if (val) {
        setTimeout(() => {
          this.timerAutoUpdateMessages = setInterval(() => {
            if (!this.$route.params.message && this.selectedMail) {
              this.getMessages(this.getMailMessagesWithoutLoading);
              this.getMailFoldersWithoutLoading(this.selectedMail);
            }
          }, ms);
        }, ms)
      } else {
        clearTimeout(this.timerAutoUpdateMessages);
      }
    }
  },
  mounted() {
    this.loadMails();
    // this.$echo.private(`mail.${this.profile.id}`)
    //     .listen('IntegrationMailNewEmail', (payload) => {
    //       console.warn(payload)
    //     });

    if (localStorage.getItem("auto_mail_messages_update") === null) {
      localStorage.setItem("auto_mail_messages_update", 'true');
    }
    setTimeout(() => {
      this.setAutoUpdateMailMessagesSettings(localStorage.getItem("auto_mail_messages_update") === "true");
      eventBus.$on('autoUpdateMailMessagesChanged', (payload) => {
        this.setAutoUpdateMailMessagesSettings(payload);
      });
    }, 5000);
  },
  watch: {
    selectedMail(val, oldVal) {
      if (oldVal && val !== oldVal) {
        this.clearMessage();
        this.clearFolders();
      }
      if (val) {
        this.foldersLoading = true;
        this.getMailFolders(this.selectedMail).then(() => {
          if (
              this.selectedSection &&
              !this.selectedMessageId
          ) {
            this.$router.replace({
              path: `/mail/${this.selectedMail}/${this.selectedSection.name}`,
              query: this.queryArgs,
            }).catch(() => {
            });
          }
        }).finally(() => this.foldersLoading = false);
      }
    },
    'mails': {
      handler(val) {
        if (val.length && !this.selectedMail) {
          this.selectedMail = +val[0].id;
        }
      },
    },
    'folders': {
      handler(val) {
        //проверка на том ли роуте выбранного ящика находимся
        if ((this.$route.params.folder && this.selectedMail === +this.$route.params.mailId)) {
          this.selectedSection = this.folders.find((i) => i.name === this.$route.params.folder);
        } else {
          this.selectedSection = val[0];
        }
      },
    },
    '$route': {
      handler: function (val) {
        if (this.$route.params.folder && !this.selectedSection) {
          this.selectedSection = {
            name: this.$route.params.folder,
            full_name: this.$route.params.folder,
            path: this.$route.params.folder
          }
        }
        this.page = +this.$route.query.page || this.page;
        this.perPage = +this.$route.query.per_page || this.perPage;

        if (this.selectedMail !== this.$route.params.mailId) {
          this.selectedMail = +this.$route.params.mailId;
        }
        if (val.params.folder && val.params.mailId && !val.params.message) {
          this.getMessages(this.getMailMessages);
        }
        if (val.params.message) {
          this.selectedMessageId = val.params.message;
          this.getMailMessage({
            mailId: this.$route.params.mailId,
            path: this.$route.params.folder,
            id: val.params.message,
          });
        } else {
          this.selectedMessageId = null;
          this.clearMessage();
        }
      },
      deep: true,
      immediate: true
    },
    message(val) {
      this.selectedMessageId = val;
    },
  },
  beforeDestroy() {
    this.$echo.leave(`mail.${this.$route.params.mailId}.${this.profile.id}`);
    this.selectedMail = null;
  }
}
</script>


<style lang="scss">
.mail {
  &__wrapper {
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 0;
    max-width: calc(75% - 24px);
    overflow: hidden;

    &-headline {
      margin-left: 20px;

      span {
        font-weight: 400;
      }
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin-right: 24px;

    &-headline {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 66px;
    }

    &-item {
      box-shadow: 0 20px 24px -4px rgba(34, 37, 57, 0.08), 0px 8px 8px -4px rgba(34, 37, 57, 0.03);
      border-radius: 10px;
      padding: 12px 18px;
      align-items: center;
      margin-bottom: 9px;
      font-weight: 400;
      font-size: 14px;
      color: $evi-headline-color;
      cursor: pointer;
      background: #fff;
      position: relative;

      &.v-skeleton-loader .v-skeleton-loader__list-item {
        width: 100%;
      }

      &--active {
        display: block;
        position: absolute;
        content: '';
        border: 6px solid transparent;
        border-left: 6px solid #808386;
        border-radius: 1px;
        right: 14px;
      }
    }
  }

  .mail-empty {
    font-weight: 600;
    font-size: 14px;
    color: #5C5C5C;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background: transparent;
  }
}

.mail-skeleton {
  border-radius: 10px;
  overflow: hidden;
  max-height: 100%;
}
</style>
