
import {mapActions, mapState} from "vuex";

export var MailAutocompleteRequestsMixin = {
    data: () => ({
        mailSearch: "",
        timerId: null,
        mailPage: 0,
        mailPerPage: 20,
        mailLoading: false,
    }),
    computed: {
        ...mapState("mail", ["mails"]),
    },
    methods: {
        ...mapActions({
            getMails: 'mail/getMailBy',
            addMails: 'mail/addMailBy',
        }),
        getMailsBy (search) {
            this.mailPage = 1;
            this.mailLoading = true;
            let args = {
                per_page: this.mailPerPage,
                page: this.mailPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getMails(args).then(() => {
                this.mailLoading = false;
            });
        },
        addMailsBy (search) {
            this.mailPage++;
            this.mailLoading = true;
            let args = {
                per_page: this.mailPerPage,
                page: this.mailPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addMails(args).then(() => {
                this.mailLoading = false;
            });
        },
        loadMails () {
            this.getMailsBy("");
        },
        mailIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addMailsBy(this.mailSearch);
            }
        },
        searchMail (val) {
            if (val) {
                this.mailPage = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getMailsBy(val);
                }, 500);
            }
        },
    },
};
